import React, { HTMLProps } from "react";
import { Link } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/libre-baskerville";
import "@fontsource/montserrat";
import "@fontsource/roboto";

import Ordered from "~/components/List/Ordered";
import Unordered from "~/components/List/Unordered";


const removeTrailingSlash = (str: string): string => {
  if (str[str.length - 1] === "/") {
    return str.slice(0, -1);
  }

  return str;
}

const isActive = (currentPath: string): boolean => {
  if (typeof window === "undefined")  return false;

  const pathname: string = window.location.pathname;

  if (removeTrailingSlash(currentPath) === removeTrailingSlash(pathname)) {
    return true;
  }

  return false;
};

const TitleText = (): JSX.Element => {
  return (
    <div>
      <h1 className="text-4xl font-bold" style={{ fontFamily: "Libre Baskerville" }}>CBD Truth</h1>
      <span className="font-medium">SEPARATE THE FACT FROM THE FICTION ABOUT CBD</span>
    </div>
  );
};

export const Title = (): JSX.Element => {
  return (
    <div className="relative flex flex-wrap md:flex-nowrap w-full p-8">
      <StaticImage
        alt="Background texture."
        className="inset-0 z-0"
        loading="eager"
        src="../../../../images/cbd-truth-texture.png"
        style={{ position: "absolute" }}
      />
      <Link to="/article/cbd/cbd-truth" className="z-50 text-cbd-truth-blue">
        <TitleText />
      </Link>
      <StaticImage
        alt="CBD Truth logo."
        className="ml-auto z-10"
        loading="eager"
        src="../../../../images/cbd-truth-logo-white.svg"
        layout="fixed"
        width={78}
        height={92}
      />
    </div>
  );
};

const NavigationLink = ({ children, href }: HTMLProps<HTMLAnchorElement>): JSX.Element => {
  if (!href) {
    href = "#";
  }
  return (
    <Link
      to={href}
      className={isActive(href)
        ? "font-medium text-xl bg-cbd-truth-box-blue px-2 py-1 outline-none focus:ring-4 focus:ring-white"
        : "font-medium text-xl px-2 py-1 outline-none focus:ring-4 focus:ring-white"
      }
    >
      {children}
    </Link>
  );
};
export const Navigation = (): JSX.Element => {
  return (
    <div className="bg-cbd-truth-blue flex flex-col space-y-3 md:space-y-0 md:flex-row md:justify-center md:space-x-12 text-white p-2">
      <NavigationLink href="/article/cbd/cbd-truth">HOME</NavigationLink>
      <NavigationLink href="/article/cbd/cbd-truth/mission">MISSION</NavigationLink>
      <NavigationLink href="/article/cbd/cbd-truth/about-us">ABOUT US</NavigationLink>
      <NavigationLink href="/article/cbd/cbd-truth/donate">DONATE</NavigationLink>
      <NavigationLink href="/article/cbd/cbd-truth/contact">CONTACT</NavigationLink>
    </div>
  );
};

const WhatIsCBD = (): JSX.Element => {
  return (
    <div className="p-10">
      <div className="flex">
        <div className="w-2/3">
          <h2 className="text-3xl font-bold mt-0" style={{ fontFamily: "Libre Baskerville" }}>What is CBD?</h2>
          <p className="mt-3 font-medium text-xl">
            <span className="font-semibold">CBD</span> comes from two varieties of cannabis: hemp and marijuana. The cannabis plant makes between 100 and 140 different chemicals called <span className="font-semibold">cannabinoids.</span>
          </p>
          <div className="relative flex">
            <button className="absolute flex px-4 py-2 bg-cbd-truth-light-blue text-xl text-cbd-truth-light-blue rounded outline-none left-0 top-0 ml-2 mt-7 z-0">
              Help CBD Truth fulfill its mission.&nbsp;<span className="underline">Donate now.</span>
            </button>
            <button className="flex px-4 py-2 bg-cbd-truth-blue text-xl text-white rounded outline-none focus:ring-4 focus:ring-cbd-truth-light-blue mt-5 font-bold z-10">
              Help CBD Truth fulfill its mission.&nbsp;<span className="underline">Donate now.</span>
            </button>
          </div>
        </div>
        <StaticImage
          alt="Eye dropper in hand next to hemp plant."
          className="w-1/3 ml-5"
          loading="eager"
          placeholder="blurred"
          src="../../../../images/cbd-truth-eye-dropper.jpg"
        />
      </div>
      <div className="mt-12">
        <p>The most abundant chemical, and best known, is tetrahydrocannabinol (THC), which causes a psychoactive intoxication, or "high." Modern marijuana flowers may contain 10% to 35% THC, while hemp contains only about 0.3%.</p>
        <p className="mt-5">The second most abundant chemical in cannabis is cannabidiol, or CBD, which doesn't cause a high like THC. CBD is not the same thing as marijuana. CBD is a single compound in the cannabis plant. Marijuana refers to a type of cannabis plant or plant material that contains hundreds of compounds, including THC and CBD.</p>
      </div>
    </div>
  );
};

const Chemicals = (): JSX.Element => {
  return (
    <div className="p-10">
      <div className="flex justify-between mt-12 text-cbd-truth-dark-blue font-bold text-2xl" style={{ fontFamily: "Libre Baskerville" }}>
        <p className="w-1/2 text-center">CBD</p>
        <p className="w-1/2 text-center">THC</p>
      </div>
      <StaticImage
        alt="Chemical diagrams of CBD and THC."
        className="w-full"
        loading="lazy"
        placeholder="blurred"
        src="../../../../images/cbd-truth-chemical-diagram.jpg"
      />
    </div>
  );
};

const WhatIsCBDUsedFor = (): JSX.Element => {
  return (
    <div className="bg-cbd-truth-super-light-blue p-10">
      <h2 className="text-3xl font-bold mt-0" style={{ fontFamily: "Libre Baskerville" }}>What is CBD used for?</h2>
      <p>"CBD is being used over the counter in a range of ways that is not supported by the science," says Dr. Jordan Tishler, instructor of medicine at Harvard Medical School and president of the Association of Cannabis Specialists. "There is still much we don't know. But aggressive marketing, hype, and word of mouth have made CBD like a drug version of the emperor's new clothes. Everyone says it works, but lab studies suggest that it's really not what people think."</p>
      <p className="mt-5">Although there are many sources that will say CBD can help almost any health issue, there are some issues that have received the most attention, including these:</p>
      <div className="mt-5">
        <Unordered>
          <li>Relieving pain</li>
          <li>Easing anxiety and depression</li>
          <li>Reducing cancer-related symptoms</li>
          <li>Reducing acne</li>
          <li>Treating neurological disorders such as epilepsy and multiple sclerosis</li>
          <li>Improving heart health</li>
          <li>Treating mental conditions, including schizophrenia</li>
          <li>Treating substance abuse</li>
          <li>Preventing diabetes</li>
        </Unordered>
      </div>
      <p className="mt-5">Your body naturally produces cannabinoids that are involved with many bodily functions, such as appetite, pain sensation, mood, and sleep. The thinking behind CBD is that it may interact with these existing cannabinoids and increase their effect. "However, this has not been proved," says Dr. Tishler.</p>
    </div>
  );
};

const IsCBDLegal = (): JSX.Element => {
  return (
    <div className="flex p-10">
      <div className="w-3/5">
        <h2 className="text-3xl font-bold mt-0" style={{ fontFamily: "Libre Baskerville" }}>Is CBD legal?</h2>
        <p>The CBD boom began in 2008 when Congress passed a law that legalized hemp in all 50 states and removed CBD from the controlled substances list. The passage of this bill led to the use of hemp for making products like paper, textiles, and concrete. CBD oil was added to this list of substances that could be made from hemp and sold.</p>
        <p className="mt-5">Whether CBD is legal may depend on where you are. At this time, CBD oil is legal in 30 states where medicinal and/or recreational marijuana is legal. Other states have CBD-specific laws. For example, some states require a prescription for CBD. CBD products cannot contain more than 0.3% THC. It is not legal to sell CBD by adding it to food or labeling it as a dietary supplement.</p>
        <p className="mt-5">Another fact can make the laws somewhat confusing. Marijuana can also produce CBD. CBD from hemp plants is federally legal, but if it comes from a marijuana plant, it is illegal. That’s because marijuana plants themselves are prohibited by the Drug Enforcement Agency.</p>
      </div>
      <StaticImage
        alt="Gavel."
        className="w-2/5 ml-5"
        loading="lazy"
        placeholder="blurred"
        src="../../../../images/cbd-truth-gavel.jpg"
      />
    </div>
  );
};

const WhatIsTheEvidenceForCBD = (): JSX.Element => {
  return (
    <div className="bg-cbd-truth-super-light-blue overflow-hidden p-10">
      <h2 className="text-3xl font-bold" style={{ fontFamily: "Libre Baskerville" }}>What is the evidence for CBD?</h2>
      <div className="mt-8">
        <StaticImage
          alt="CBD spilling out of container."
          className="w-1/3 float-left mr-5 mb-5"
          loading="lazy"
          placeholder="blurred"
          src="../../../../images/cbd-truth-hemp-spilling.jpg"
        />
        <p>Even though CBD gets rave reviews from many users, the current science does not back up most claims, according to Dr. Tishler. "The main problem is that not enough medical studies have been done to offer any kind of clear guidance," he says.</p>
        <p className="mt-5">CBD critics are absolutely correct when they state that definitive clinical evidence is lacking to recommend CBD. The few studies available on CBD have focused on how it helps with anxiety and sleep. One study found that a single dose of 600 milligrams (mg) of CBD decreased anxiety in people about to give a public speech. In addition, people who took 25 mg of CBD daily for a month reduced their anxiety and had better quality of sleep, and the effect lasted for several months, according to a study published online Jan. 7, 2019, by <span className="italic">The Permanente Journal</span>. "But these were not controlled trials, so they must be viewed skeptically," says Dr. Tishler.</p>
        <p className="mt-5">The number of clinical trials investigating the effectiveness of CBD for treating a number of health conditions is increasing, and many of these studies are underway. These studies will provide the scientific evidence to know whether CBD is truly effective for treating medical conditions as well as problems or side effects that may result from its use.</p>
        <p className="mt-5">Even though there needs to be many more studies to find out whether CBD works and for what conditions, many people believe in CBD's effects. Because people often turn to CBD as a last resort to deal with their pain or anxiety, they may be experiencing a placebo effect. According to Dr. Tischler, "They hear it works wonders, so they believe it will help them, too."</p>
      </div>
    </div>
  );
};

const ProblemsWithCBDUse = (): JSX.Element => {
  return (
    <div className="p-10 mb-10">
      <div className="flex">
        <div className="w-3/5">
          <h2 className="text-3xl font-bold mt-0" style={{ fontFamily: "Libre Baskerville" }}>Problems with CBD use</h2>
          <p className="mt-5">The data on the safety of CBD are limited, but people should consider possible side effects before taking CBD. Some side effects of CBD include the following:</p>
          <div className="mt-5">
            <Unordered>
              <li>Digestive issues, such as diarrhea and decreased appetite</li>
              <li>Drowsiness or other chages in alertness</li>
              <li>Increased heart rate and coordination problems</li>
              <li>Changes in mood, such as irritability and agitation</li>
              <li>Slower reaction and memory loss</li>
              <li>Red eyes</li>
              <li>Dry mouth</li>
            </Unordered>
          </div>
        </div>
        <StaticImage
          alt="Marijuana leaf."
          className="w-2/5 ml-5"
          loading="lazy"
          placeholder="blurred"
          src="../../../../images/cbd-truth-marijuana-leaf.jpg"
        />
      </div>
      <div>
        <p className="mt-5">These side effects may be short-term for most people, but long-term use of CBD may cause psychiatric effects in adolescents, including increasing the risk for schizophrenia and other mental disorders.</p>
        <p className="mt-5">CBD use may be harmful or dangerous in some cases. People with some conditions may want to be extra cautious about using CBD. These conditions include the following: </p>
        <div className="mt-5">
          <Ordered>
            <li>People who take prescription drugs. CBD can affect how other drugs work and can cause serious side effects.</li>
            <li>People who have problems with their liver. CBD can cause liver injury.</li>
            <li>People with low blood pressure. Some studies report that CBD lowers blood pressure, which could be a concern for     people already dealing with low blood pressure.</li>
            <li>People trying to become pregnant, currently pregnant, or breastfeeding. It is unknown how CBD affects a developing baby, so it is safest to minimize exposure to cannabinoids while pregnant or breastfeeding.</li>
            <li>People who want to use CBD instead of another treatment. People should talk to their doctor before changing their current treatment.</li>
          </Ordered>
        </div>
      </div>
      <div className="bg-cbd-truth-box-blue mt-10 p-5">
        <h3 className="text-white text-xl w-full">There are many important questions about CBD use that scientists do not know the answers to yet, such as these:</h3>
        <div className="flex flex-wrap justify-center">
          <div className="bg-white rounded-lg p-5 m-5 max-w-sm">
            <p>What happens if someone takes CBD daily for a long period of time?</p>
          </div>
          <div className="bg-white rounded-lg p-5 m-5 max-w-sm">
            <p>How much CBD triggers the risks associated with CBD use?</p>
          </div>
          <div className="bg-white rounded-lg p-5 m-5 mx max-w-sm">
            <p>Does how a person takes CBD (e.g., by mouth, topical skin creams, smoking, vaping) affect a person's outcomes?</p>
          </div>
          <div className="bg-white rounded-lg p-5 m-5 max-w-sm">
            <p>How does CBD use affect the developing brain of a child?</p>
          </div>
          <div className="bg-white rounded-lg p-5 m-5 max-w-sm">
            <p>What effects does CBD have on a developing fetus?</p>
          </div>
          <div className="bg-white rounded-lg p-5 m-5 max-w-sm">
            <p>Does CBD cause reproductive toxicity or damage to fertility in males or male offspring of women who have been exposed?</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const NotAllCBDProductsAreHighQuality = (): JSX.Element => {
  return (
    <div className="bg-cbd-truth-super-light-blue overflow-hidden p-10">
      <h2 className="text-3xl font-bold mt-0" style={{ fontFamily: "Libre Baskerville" }}>Not All CBD Products Are High Quality</h2>
      <p className="mt-5">Clinical trials give patients high-quality, safe products which contain a reliable quantity of CBD. A 2017 study published in the Journal of the American Medical association by researchers at Johns Hopkins School of Medicine and University of Pennsylvania Perelman School of Medicine found that more than two-thirds of the products they purchased online were mislabeled, containing more CBD than listed on the label, less of it, or none at all. The tests also found some CBD products with more THC than listed on the label, sometimes much more. Also, tests conducted by the Food and Drug Administration of CBD products found that many did not contain the levels of CBD they claimed to contain.</p>
      <p className="mt-5">Because the supplement industry in the United States isn’t heavily regulated, it can be difficult to know what you are actually buying. If you purchase CBD from dishonest or potentially unsafe manufacturers, you might experience very different side effects.</p>
      <p className="mt-5">When scientists recently tested a variety of publicly available CBD products, only 31% were accurately labeled.</p>
      <div>
        <StaticImage
          alt="CBD oil dropper."
          className="float-right w-2/5 ml-5 mb-5"
          loading="lazy"
          src="../../../../images/cbd-truth-mouth-dropper.jpg"
        />
        <p className="mt-5">CBD products from unreliable or unsafe manufacturers may contain</p>
        <div className="mt-5">
          <Unordered>
            <li>Pesticides and heavy metals from bad farming practices</li>
            <li>High levels of THC (more than legal)</li>
            <li>Synthetic cannabinoids</li>
            <li>Contaminants such as mold, bacteria, harmful chemicals, etc.</li>
          </Unordered>
        </div>
        <p className="mt-5 font-bold">Sometimes CBD products don't contain any CBD at all—in which case you won't experience <span className="italic">any</span> effects, positive or negative.</p>
        <p className="mt-5">It's hard to imagine all the different side effects you might experience from contaminated or synthetic products. The worst effects of dishonest CBD products could be long-term damage to your body that you won't immediately experience as a side effect.</p>
        <p className="mt-5">It can be very difficult for people to know if the product they bought is good or bad. Because there are few rules about how hemp-derived CBD products should be tested, sellers could be selling contaminated or even adulterated CBD products. If you’re suspicious about the safety of your current CBD products, do yourself a favor and throw them away.</p>
      </div>
    </div>
  );
};

const References = (): JSX.Element => {
  return (
    <div className="p-10">
      <h2 className="text-3xl font-bold mt-0" style={{ fontFamily: "Libre Baskerville" }}>References</h2>
      <p>Akpan, N., & Leventhal, J. (2019). Is CBD legal? Here’s what you need to know, according to science. <span className="italic">PBS News Hour</span>. https://www.pbs.org/newshour/science/is-cbd-legal-heres-what-you-need-to-know-according-to-science</p>
      <p className="mt-5">Bergamaschi, M. M., Queiroz, R. H., Chagas, M. H., de Oliveira, D. C., De Martinis, B. S., Kapczinski, F., Quevedo, J., Roesler, R., Schröder, N., Nardi, A. E., Martín-Santos, R., Hallak, J. E., Zuardi, A. W., & Crippa, J. A. (2011). Cannabidiol reduces the anxiety induced by simulated public speaking in treatment-naïve social phobia patients. <span className="italic">Neuropsychopharmacology: official publication of the American College of Neuropsychopharmacology</span>, 36(6), 1219–1226. https://doi.org/10.1038/npp.2011.6</p>
      <p className="mt-5">Bonn-Miller, M. O., Loflin, M. J. E., Thomas, B. F., Marcu, J. P., Hyke, T., Vandrey, R. (2017). Labeling Accuracy of Cannabidiol Extracts Sold Online. AMA, 318(17):1708–1709. doi:10.1001/jama.2017.11909. https://jamanetwork.com/journals/jama/fullarticle/2661569</p>
      <p className="mt-5">Gill, L. L. (2019). CBD Is Legal—But Is It Safe? Consumer Reports. https://www.consumerreports.org/cbd/cbd-may-be-legal-but-is-it-safe/</p>
      <p className="mt-5">Harvard Men's Health Watch. (2019). Know the facts about CBD products. https://www.health.harvard.edu/staying-healthy/know-the-facts-about-cbd-products</p>
      <p className="mt-5">Shannon, S., Lewis, N., Lee, H., & Hughes, S. (2019). Cannabidiol in Anxiety and Sleep: A Large Case Series. <span className="italic">The Permanente Journal</span>, 23, 18–041. https://doi.org/10.7812/TPP/18-041</p>
      <p className="mt-5">U. S. Food and Drug Administration. (2020). What You Need to Know (And What We’re Working to Find Out) About Products Containing Cannabis or Cannabis-derived Compounds, Including CBD. https://www.fda.gov/consumers/consumer-updates/what-you-need-know-and-what-were-working-find-out-about-products-containing-cannabis-or-cannabis</p>
    </div>
  );
};

export const Footer = (): JSX.Element => {
  return (
    <div className="flex-grow bg-cbd-truth-blue flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:justify-between lg:items-center lg:space-x-5 text-white p-10">
      <TitleText />
      <span className="text-2xl md:text-4xl" style={{ fontFamily: "Roboto" }}>WWW.CBDTRUTH.ORG</span>
      <div className="text-sm">
        <p><i className="fas fa-phone-rotary" />800-555-1687</p>
        <p>&copy; 2022 CBD Truth</p>
      </div>
    </div>
  );
};

const Body = (): JSX.Element => {
  return (
    <div className="bg-white flex-grow text-cbd-truth-dark-blue font-light text-sm">
      <WhatIsCBD />
      <Chemicals />
      <WhatIsCBDUsedFor />
      <IsCBDLegal />
      <WhatIsTheEvidenceForCBD />
      <ProblemsWithCBDUse />
      <NotAllCBDProductsAreHighQuality />
      <References />
    </div>
  );
};


const CBDTruthPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Montserrat" }}>
      <Title />
      <Navigation />
      <Body />
      <Footer />
    </div>
  );
};

export default CBDTruthPage;
